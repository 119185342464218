import React from 'react'
import Header from '../components/header'
// import Sign from '../components/sign'
import App from '../components/app'
import Blog from '../components/blog'
import Navbar from '../components/navbar'

export default function resources() {
  return (
    <div>
        <Navbar/>
        <Header/>
        {/* <Sign/> */}
        <App/>
        <Blog/>

    </div>
  )
}
