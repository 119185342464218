import React from 'react'
import Logo from '../../static/images/logo.jpg'

export default function blog() {
  return (
    <div>
        <section class="py-24 md:py-40 bg-black">
  <div class="container px-4 mx-auto">
    <div class="flex items-center mb-24">
      <span class="font-heading text-xl"></span>
      <div class="mx-4 rounded-full bg-gray-200 h-1 w-1"></div>
      <span class="font-heading text-xl text-white">Our Blog</span>
    </div>
    <a href="index.html" target='_blank'>
    <div class="flex flex-wrap -mx-4">
      <div class="w-full lg:w-1/2 px-4 mb-14 lg:mb-0">
        <img class="block mx-auto" src={Logo} alt=""/>
      </div>
      <div class="w-full lg:w-1/2 px-4">
        <div class="max-w-lg mx-auto">
          <div class="mb-12">
            <span class="inline-block px-5 py-2 mr-6 text-sm bg-white rounded-full">News</span>
            <span class="font-light text-sm text-gray-200">January 13, 2022</span>
          </div>
          <h2 class="font-heading text-4xl sm:text-5xl mb-10 text-white bg-black">
            <span>Your Home:</span>
            <span>Designed by experts, inspired by real life</span>
          </h2>
          <p class="max-w-md font-medium mb-8 bg-black text-white">The house by the pond cras ornare, some chords for a three moments, like a sense of truth.</p>
          <div class="mb-16"><a class="inline-block mr-6 text-indigo-500 hover:text-indigo-600" href="#">#Interiodesign</a><a class="inline-block mr-6 text-indigo-500 hover:text-indigo-600" href="#">#Design</a><a class="inline-block text-indigo-500 hover:text-indigo-600" href="#">#Application</a></div>
          <a class="inline-block w-full sm:w-auto px-7 py-4 text-center font-medium bg-green-600 hover:bg-indigo-600 text-white rounded transition duration-250" href="#">Learn More</a>
        </div>
      </div>
    </div>
    </a>
  </div>
</section>
    </div>
  )
}
