import React from 'react'
import Pixone from "../../static/images/pixone.jpg"
import Logo1 from "../../static/images/Logo1.png"

export default function app() {
  return (
    <div>
      <section class="pt-36 bg-gray-50 overflow-hidden">
  <div class="container mx-auto px-4">
    <div class="flex flex-wrap -m-6">
      <div class="w-full md:w-1/2 p-6">
        <div class="max-w-xl">
          <h2 class="mb-6 font-heading font-bold text-6xl sm:text-7xl text-gray-900">Get our mobile app to make the process faster.</h2>
          <p class="mb-16 text-gray-600 text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Malesuada tellus vestibulum, commodo pulvinar id fames nunc. Turpis leo turpis non, id ut nunc.</p>
          <div class="flex flex-wrap -m-2.5 pb-10 xl:pb-0">
            <div class="w-auto p-2.5">
              <a href="#">
                <img src="gradia-assets/logos/brands/app-store2.png" alt=""/>
              </a>
            </div>
            <div class="w-auto p-2.5">
              <a href="#">
                <img src="gradia-assets/logos/brands/play-store2.png" alt=""/>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="flex w-full md:w-1/2 p-6">
        <img class="self-end mx-auto" src="gradia-assets/images/applications/mobile.png" alt=""/>
      </div>
    </div>
  </div>
</section>
    </div>
  )
}
